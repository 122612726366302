<script setup>
import {onMounted, onBeforeMount, watchEffect, watch, ref} from 'vue'
import { useAppStore } from './stores/app';
import { useRouter, useRoute } from 'vue-router'

const router = useRouter();

const appStore = useAppStore();



onBeforeMount(() => {
  if(appStore.checkAuth()){
    if(appStore.tokenInterval == false){
      appStore.startTokenInterval();
    }
  }
})

onMounted(() => {
  document.querySelector('meta[property="og:image"]').setAttribute("content", import.meta.env.VITE_APP_OG_IMAGE);
  document.querySelector('meta[property="og:url"]').setAttribute("content", import.meta.env.VITE_APP_URL);
  document.querySelector('meta[name="description"]').setAttribute("content", import.meta.env.VITE_APP_DESCRIPTION);
  document.querySelector('meta[property="og:description"]').setAttribute("content", import.meta.env.VITE_APP_DESCRIPTION);
  

})
</script>

<template>
  <router-view />
</template>

<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}


</style>
