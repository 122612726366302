import { createWebHistory, createRouter } from 'vue-router';
import dashboardRoutes from './dashboardRoutes';
import publicRoutes from './publicRoutes';
import clientRoutes from './clientRoutes';



import { useAppStore } from '../stores/app'


const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes:  [
        {
            name: 'public-parent',
            component: () => import('../layouts/PublicLayout.vue'),
            path: '/',
            meta: { requiresAuth: false, permissions: [] },
            children: publicRoutes,
            sensitive: true,
            strict: true
        },
        {
            path: '/admin/',
            component: () => import('../layouts/DashboardLayout.vue'),
            name: 'admin-parent',
            meta: { requiresAuth: true },
            children: dashboardRoutes,
            sensitive: true,
            strict: true
        },
        {
            path: '/client/',
            component: () => import('../layouts/DashboardLayout.vue'),
            name: 'client-parent',
            meta: { requiresAuth: true },
            children: clientRoutes,
            sensitive: true,
            strict: true
        },
        {
            path: '',
            sensitive: true,
            strict: true,
            redirect: to => {
              return { path: '/sign-in' }
            },
        },
        {
            path: "/:pathMatch(.*)*",
            name: "not-found",
            sensitive: true,
            strict: true,
            component: () => import('../views/NotFound.vue'),
            
        }

        
    ]
});
    
router.beforeEach((to, from, next) => {
    const appStore = useAppStore();
    const user = appStore.getUserAuth();
    if(user.value == null) appStore.signoutAuth();


    if (to.meta.requiresAuth && !appStore.isAuthenticated) {
        // if(to.meta.permissions.includes(user.permission)){
        //     next();
        // }
        next({ name: 'sign-in' });
        return;
    }else{
        if(appStore.isAuthenticated && (to.name == "sign-in" || to.name == "forgot-password" || to.name == "activate-client" || to.name == "reset-password" || to.name == "activate-user")){
            next({ name: 'dashboard'})
            return;
        }

        if(to.meta.requiresAuth && appStore.isAuthenticated && to.meta?.permissions && !to.meta.permissions.includes(user.value.permission)){
            
            next({ name: 'dashboard' });
            return;
        }
    } 

    setMetaData(to, appStore);
    window.scrollTo(0, 0); // reset scroll position to top of page
    next();
});

router.beforeResolve(to => {
    const appStore = useAppStore();
    if (to.meta.requiresAuth) {
        appStore.checkBeforeRoute();
    }
})

function setMetaData(to, appStore){
    if(to.meta?.title){
        document.title = to.meta.title;
        appStore.metaTitle = to.meta.title;
        document.querySelector('meta[property="og:title"]').setAttribute("content", to.meta.title);
    }
}



 export default router
  


