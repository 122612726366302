
const publicRoutes= [
    {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('../views/SignIn.vue'),
        meta: {
            title: 'Banner Insite - Sign In Admin',
        },
        sensitive: true,
        strict: true
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/ForgotPassword.vue'),
        meta: {
            title: 'Banner Insite - Forgot Password',
        },
        sensitive: true,
        strict: true
    },
    {
        path: '/activate-client/:uuid',
        name: 'activate-client',
        component: () => import('../views/ActivateClient.vue'),
        meta: {
            title: 'Banner Insite - Client Registration',
        },
        sensitive: true,
        strict: true,
        props: true
    },
    {
        path: '/activate-user/:uuid',
        name: 'activate-user',
        component: () => import('../views/ActivateUser.vue'),
        meta: {
            title: 'Banner Insite - User Registration',
        },
        sensitive: true,
        strict: true,
        props: true
    },
    {
        path: '/reset-password/:uuid',
        name: 'reset-password',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
            title: 'Banner Insite - Reset Password',
        },
        sensitive: true,
        strict: true,
        props: true
    }
]

export default publicRoutes;